import {useEffect, useRef} from "react"
import {useMap} from "react-leaflet"

const UpdateMapViewportLogic = ({geoJsonKey, groupRef, displayedMarkers, jobType}) => {
    // Access the map context with the useMap hook
    const map = useMap()

    // Reset the bounds of the map based on the displayed markers
    const updateMapPosition = () => {
        if (displayedMarkers && displayedMarkers.length > 0 && map && groupRef.current) {
            const layer = groupRef.current
            if (layer) {
                try {

                console.log("Bounds: ", layer.getBounds().pad(0.5))
                map.fitBounds(layer.getBounds().pad(0.5))
            }catch (e) {
                    console.log("Error Bounds: ", e)
                }
            }
        }
    }

    // useEffect Hook to reset viewport when geoJson changes
    useEffect(() => {
        updateMapPosition()
    }, [jobType, displayedMarkers]) //eslint-disable-line

    return null
}

export default UpdateMapViewportLogic