import {useMap, useMapEvents} from "react-leaflet"
import L from "leaflet"

const HandleMapZoom = () => {
    // Access the map context with the useMap hook
    const map = useMap()
    let lastZoom = map.getZoom();
    // Get visible markers on the map
    const handleMapZoom = () => {
        let popupPane = document.getElementsByClassName("leaflet-popup-pane");
        if (popupPane.length > 0) {
            let zoom = map.getZoom()
            if (zoom < lastZoom) {
                map.closePopup();
            }
            lastZoom = map.getZoom();
        }
    }

    useMapEvents({
        zoomend: () => handleMapZoom(), moveend: () => handleMapZoom(),
    })

    return null;

}
export default HandleMapZoom
