import stores from "./stores.json"
import {removeAccents} from "./helper-functions";

let INT_EXT = process.env.INT_EXT;
if (!["internal", "external"].includes(INT_EXT)) {
    INT_EXT = "external";
}

const positionUrl = (job, store, lang, head, path, locale) => {
    let parentPath = lang === "FR" ? "/carrieres/" : "/careers/";
    let city, state = "na";
    if (store.hasOwnProperty("city")) city = store['city'].toString();
    if (store.hasOwnProperty("province")) state = lang === "FR" ?  store['province_fr'].toString() :  store['province'].toString()
    let url = parentPath + head + "-" + city + "-" + state + "-" + path.split("-")[0].replace("/processes/", "") + "-" + locale;
    url = url.toLowerCase();
    url = url.replaceAll("-/ ", "-")
    url = url.replaceAll("-/ ", "-")
    url = url.replaceAll("   ", "-")
    url = url.replaceAll("  ", "-")
    url = url.replaceAll(" ", "-")
    url = url.replaceAll(", ", "-")
    url = url.replaceAll(",", "")
    url = url.replaceAll("(", "")
    url = url.replaceAll("(", "")
    url = url.replaceAll(")", "")
    url = url.replaceAll("---", "-")
    url = url.replaceAll("--", "-")
    url = url.replaceAll("-/-", "-")
    url = url.replaceAll("-&-", "-")
    url = removeAccents(url)
    return url;
}
const PositionScrubber = (jobs) => {

    let language = "en";

    // Set the target language from the environment variable if it is set.
    if (process.env.LANG) {
        language = process.env.LANG;
    }

    // console.log("LANG: ", language);

    // Geo location object.
    let geoJson = [];

    let jobTypes = ["store", "dist", "head"]

    function randomNumber(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    // If there are jobs
    if (jobs) {
        let destributionOptions = ["Internal // Interne", "External // Externe", "Internal & External // Interne et Externe"];

        // Loop thru each Job
        for (let job in jobs) {

            if (jobs.hasOwnProperty(job)) {
                let headline, url, path, locale = "";
                if (jobs[job].hasOwnProperty('applyUrl')) {
                    url = new URL(jobs[job]['applyUrl'].toString())
                    path = url.pathname;
                    locale = url.searchParams.get("locale");
                } else {
                    continue;
                }

                // If the job is the wrong language, skip the job.
                if (language.toUpperCase() === "FR" && locale === "en") {
                    continue;
                }
                if (language.toUpperCase() !== "FR" && locale === "fr_CA") {
                    continue;
                }
                const internalExternal = jobs[job].hasOwnProperty("positionDistribution") ? jobs[job].positionDistribution.toString() : null;
                if (!internalExternal) continue;
                if (!destributionOptions.includes(internalExternal)) continue;
                // console.log("positionDistribution: ", internalExternal === destributionOptions[0])
                if (internalExternal === destributionOptions[1] && INT_EXT === "internal") {
                    // console.log("Excluding: ", internalExternal)
                    continue;
                }
                if (internalExternal === destributionOptions[0] && INT_EXT === "external") {
                    // console.log("Excluding: ", internalExternal)
                    continue;
                }


                if (jobs[job].hasOwnProperty('title')) {
                    headline = jobs[job]['title'].toString();
                } else {
                    continue;
                }

                let store;
                if (jobs[job].hasOwnProperty('storeNumber')) {
                    store = jobs[job]['storeNumber'].toString();
                } else {
                    continue;
                }

                if (store !== null) {
                    // // console.log("Store Number: ", store)
                    if (locale !== undefined) {

                        let head = "error";
                        if (headline !== null) {
                            head = headline;
                        }
                        let obj = geoJson.find(job => job.properties.storeNumber === store);
                        let storeDetails = stores.find(sto => sto.storeNo.toString() === store);
                        // console.log("Store Details: ", storeDetails);
                        if (storeDetails !== undefined) {
                            if (obj !== undefined) {
                                // If the job is already in the object, and the locale is different, add this locale to the list.
                                if (obj.hasOwnProperty('properties')) {
                                    // for (let i = 0; i < 9; i++) {
                                    if (obj.properties.hasOwnProperty('jobs')) {
                                        obj.properties.jobs.push({
                                            "language": locale,
                                            "url": url.href,
                                            "position_url": positionUrl(jobs[job], storeDetails, language, head, path, locale),
                                            "path": path,
                                            "headline": head,
                                            "jobCategory": jobs[job].hasOwnProperty('jobCategory') ? jobs[job].jobCategory : "n/a",
                                            "jobStatus": jobs[job].hasOwnProperty('jobStatus') ? jobs[job].jobStatus : "n/a",
                                            "type": storeDetails.hasOwnProperty('type') ? storeDetails.type : "na"
                                        })
                                    }
                                }
                                // }

                            }
                            // If not add the job to the list.
                            else {
                                // console.log("Jobs~: ", language, head, path, locale);
                                // console.log("Jobs1~: ", jobs[job]);
                                // console.log("Jobs2~: ", storeDetails);

                                // console.log("pos url: ", positionUrl(jobs[job], storeDetails, language, head, path, locale))
                                geoJson.push({
                                    "type": "Feature",
                                    "geometry": {
                                        "type": "Point",
                                        "coordinates": [storeDetails.latitude, storeDetails.longitude]
                                    },
                                    "properties": {
                                        "jobs": [
                                            {
                                                "language": locale,
                                                "url": url.href,
                                                "path": path,
                                                "headline": head,
                                                "position_url": positionUrl(jobs[job], storeDetails, language, head, path, locale),
                                                "type": storeDetails.hasOwnProperty('type') ? storeDetails.type : "na",
                                                "jobCategory": jobs[job].hasOwnProperty('jobCategory') ? jobs[job].jobCategory : "n/a",
                                                "jobStatus": jobs[job].hasOwnProperty('jobStatus') ? jobs[job].jobStatus : "n/a",
                                            }
                                        ],
                                        "storeNumber": store,
                                        "city": `${storeDetails.city ? storeDetails.city : "na"}`,
                                        "banner": `${storeDetails.banner ? storeDetails.banner : "na"}`,
                                        "entity": `${storeDetails.storeName ? storeDetails.storeName : "na"}`,
                                        "province": `${language === "FR" ? storeDetails.province_fr : storeDetails.province}`
                                    }
                                },)

                            }


                        }
                    }
                }


            }
        }
        // console.log("geoJson: ", geoJson)
        return geoJson
    }
}


export default PositionScrubber;