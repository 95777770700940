import React, {useEffect, useRef, useState} from "react"
import {FormControl, InputLabel, MenuItem, Select, TextField, CircularProgress} from "@mui/material";
import {MapContainer, TileLayer, FeatureGroup, GeoJSON, MapConsumer} from "react-leaflet"
import AddLocate from "./add-locate"
import MarkerClusterGroup from 'react-leaflet-markercluster';
import "./map.css"
import PositionScrubber from "./PositionScrubber";
import {distance, makeRandomKey, createPopups, createClusters} from "./helper-functions"
import UpdateMapPosition from "./UpdateMapPosition"
import jsonJobs from "../../mapJobs.json"
import xmlConfig from "../../xmlConfig.json"
import HandleMapZoom from "./HandleMapZoom";

const isFrench = process.env.LANG === "FR";
const originalData = PositionScrubber(jsonJobs);


/*      STATES      */
const JobMap = () => {

    // const [originalData, setOriginalData] = useState()

    // User Geo Location Coordinates
    const [coords, setCoords] = useState([null, null]);

    //  Track which markers are being actively displayed on the map
    const [displayedMarkers, setDisplayedMarkers] = useState()

    //  GeoJson Key to handle updating geojson inside react-leaflet
    const [geoJsonKey, setGeoJsonKey] = useState("initialKey123abc")

    // Job Type Dropdown
    const [jobType, setJobType] = useState("");

    // const [locateZoomStatus, setLocateZoomStatus] = useState();
    /*      REFS      */
    const groupRef = useRef();

    const clusterRef = useRef();

    let map = null;
    /*      USE EFFECTS     */
    useEffect(() => {
        const newKey = makeRandomKey(10);
        setGeoJsonKey(newKey)
    }, [displayedMarkers])

    useEffect(() => {
        let progressCircle = document.getElementById("job_progress_circle");
        progressCircle.style.display = "none";

        function getClosestPoint(jobData) {

            if (!jobData || !coords[0] || !coords[1]) return;

            for (let job in jobData) {
                if (jobData.hasOwnProperty(job)) {
                    jobData[job]['distance'] = distance(parseFloat(jobData[job].geometry.coordinates[1]), parseFloat(jobData[job].geometry.coordinates[0]), coords[0], coords[1], 'K')
                }
            }
            jobData.sort(function (a, b) {
                let distA = a.distance
                let distB = b.distance
                if (distA < distB) {
                    return -1;
                } else if (distA > distB) {
                    return 1;
                }
                return 0;
            });
            if (!jobData[0] || !jobData[1]) return;
            document.getElementById("location_2_city").innerText = `${jobData[1].properties.city}, ${jobData[1].properties.province}`;
            document.getElementById("location_1_city").innerText = `${jobData[0].properties.city}, ${jobData[0].properties.province}`;
            document.getElementById("location_1_href").href = `/${isFrench ? 'recherche' : 'search'}/?term=${jobData[0].properties.city}`;
            document.getElementById("location_2_href").href = `/${isFrench ? 'recherche' : 'search'}/?term=${jobData[1].properties.city}`;
            document.getElementById("location_all_jobs").href = `/${isFrench ? 'recherche' : 'search'}/?term=${jobData[0].properties.province}`;
            document.getElementById("location_2_city2").innerText = `${jobData[1].properties.city}, ${jobData[1].properties.province}`;
            document.getElementById("location_1_city2").innerText = `${jobData[0].properties.city}, ${jobData[0].properties.province}`;
            document.getElementById("location_1_href2").href = `/${isFrench ? 'recherche' : 'search'}/?term=${jobData[0].properties.city}`;
            document.getElementById("location_2_href2").href = `/${isFrench ? 'recherche' : 'search'}/?term=${jobData[1].properties.city}`;
            document.getElementById("location_all_jobs2").href = `/${isFrench ? 'recherche' : 'search'}/?term=${jobData[0].properties.province}`;
            let jobs1 = isFrench ? "Poste" : "Job";
            if (jobData[0].properties.jobs.length > 1) {
                jobs1 = isFrench ? "Postes" : "Jobs";
            }
            let jobs2 = isFrench ? "Poste" : "Job";
            if (jobData[1].properties.jobs.length > 1) {
                jobs2 = isFrench ? "Postes" : "Jobs";
            }
            document.getElementById("location_2_jobs").innerText = `${jobData[1].properties.jobs.length} ${jobs2}`;
            document.getElementById("location_1_jobs").innerText = `${jobData[0].properties.jobs.length} ${jobs1}`;
            document.getElementById("location_2_jobs2").innerText = `${jobData[1].properties.jobs.length} ${jobs2}`;
            document.getElementById("location_1_jobs2").innerText = `${jobData[0].properties.jobs.length} ${jobs1}`;
        }

        getClosestPoint(displayedMarkers);

    }, [coords, displayedMarkers])
    useEffect(() => {

        // Get the users Location from the browser. Store the coordinates
        function getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function setCoordinates(position) {
                    const lat = position.coords.latitude;
                    const long = position.coords.longitude
                    setCoords([lat, long]);
                    // setCoords([43.700287227518146, -79.30802691936593]) // Toronto
                });
            }
        }

        let data = originalData;
        // setOriginalData(data);
        // Get Platform Data
        setDisplayedMarkers(data);

        // for (let locations in data) {
        //     let f = data[locations].properties.jobs;
        //     for (let j in f) {
        //         console.log("originalData: ", data[locations].properties.city, f[j].headline, f[j].type)
        //     }
        // }
        const newKey = makeRandomKey(10);
        setGeoJsonKey(newKey);
        getLocation();
        let url = new URL(window.location.href);
        let type = url.searchParams.get("type");
        if (type) {
            if (type) {
                if (type.includes("dist")) {
                    type = "dist";
                } else if (type.toString().includes(isFrench ? "magasin" : "store")) {
                    type = "store";
                } else if (type.includes(isFrench ? "head" : "head")) {
                    type = "head";
                } else {
                    type = "";
                }
                setJobType(type);
                if (data.length > 0) {
                    if (!["store", "head", "dist"].includes(type)) {
                        setDisplayedMarkers(PositionScrubber(jsonJobs))
                    } else {
                        let filteredMarkers = [];
                        // Loop each marker
                        for (let marker in data) {
                            if (data.hasOwnProperty(marker)) {
                                if (data[marker].hasOwnProperty("properties")) {
                                    if (data[marker].properties.hasOwnProperty("jobs")) {
                                        // Grab all the jobs on that marker
                                        let locationJobs = data[marker].properties.jobs;
                                        // console.log("Jobs: ", locationJobs)
                                        // Remove all jobs who arent the selected type.
                                        // let tempJobs = jobs.filter((job) => job.type === type);
                                        let tempJobs = [];
                                        for (let j in locationJobs) {
                                            if (locationJobs.hasOwnProperty(j)) {
                                                if (locationJobs[j].type === type) {
                                                    tempJobs.push(locationJobs[j])
                                                } else {
                                                    // console.log("Removing: ", locationJobs[j]);
                                                }
                                                // console.log("Location: ", locationJobs[j])
                                            }
                                        }
                                        // If one or more jobs are the selected type, add this marker with those jobs
                                        console.log("Temp Jobs: ", tempJobs)
                                        if (tempJobs && tempJobs.length > 0) {
                                            const tempMarker = data[marker];
                                            tempMarker["properties"]["jobs"] = tempJobs;
                                            // console.log("Temp marker: ", tempMarker)
                                            filteredMarkers.push(tempMarker);
                                        }
                                    }
                                }
                            }
                        }
                        setDisplayedMarkers(filteredMarkers)
                    }
                }
            }
        }

    }, []);

    /*      FUNCTIONS       */
    const handleJobTypeChange = (event) => {
        let data = originalData;
        let type = event.target.value;
        // console.log(type)
        // console.log("Original Data: ", data)
        // for (let locations in data) {
        //     let f = data[locations].properties.jobs;
        //     for (let j in f) {
        //         console.log("Updated", data[locations].properties.city, f[j].headline, f[j].type)
        //     }
        // }
        if (data.length > 0) {
            if (type === 'all') {
                setDisplayedMarkers(PositionScrubber(jsonJobs))
            } else {
                // TODO FILTER PROPERLY, CURRENTLY REMOVING ANY LOCATION THAT DOES NOT CONTAIN ALL JOBS OF THE SAME TYPE. So if a location has one dist job and one store job, it won't show for any of the options, because they both don't match.
                // data = data.filter(
                //     (marker) => marker.properties.jobs.every(job => job.type === event.target.value)
                // )
                let filteredMarkers = [];

                // Loop each marker
                for (let marker in data) {
                    if (data.hasOwnProperty(marker)) {
                        if (data[marker].hasOwnProperty("properties")) {
                            if (data[marker].properties.hasOwnProperty("jobs")) {
                                // Grab all the jobs on that marker
                                let locationJobs = data[marker].properties.jobs;
                                // console.log("Jobs: ", locationJobs)
                                // Remove all jobs who arent the selected type.
                                // let tempJobs = jobs.filter((job) => job.type === type);
                                let tempJobs = [];
                                for (let j in locationJobs) {
                                    if (locationJobs.hasOwnProperty(j)) {
                                        if (locationJobs[j].type === type) {
                                            tempJobs.push(locationJobs[j])
                                        } else {
                                            // console.log("Removing: ", locationJobs[j]);
                                        }
                                        // console.log("Location: ", locationJobs[j])
                                    }
                                }
                                // If one or more jobs are the selected type, add this marker with those jobs
                                // console.log("Temp Jobs: ", tempJobs)
                                if (tempJobs && tempJobs.length > 0) {
                                    const tempMarker = data[marker];
                                    tempMarker["properties"]["jobs"] = tempJobs;
                                    // console.log("Temp marker: ", tempMarker)
                                    filteredMarkers.push(tempMarker);
                                }
                            }
                        }
                    }
                }
                setDisplayedMarkers(filteredMarkers)
            }
        }
        setJobType(type);
    };

    /*      RETURN      */
    if (typeof window !== 'undefined') {
        return (<>

                {/*Search Form*/}
                <section className={"pt5 pt3-lg pb3 section-job-search"}>

                    {/*H2 Title*/}
                    <div className={"c tac-lg"}>
                        <h2 className={"mb0 mt2 mb2"}>{process.env.LANG === "FR" ? xmlConfig.internalExternal === "internal" ? "Découvrir les affichages internes" : "Découvrir les emplois" : xmlConfig.internalExternal === "internal" ? "Explore Internal Postings" : "Explore Jobs"}</h2>
                    </div>

                    {/*Form*/}
                    <form action={isFrench ? '/recherche/' : "/search/"} className={"r jc-lg afe fww w100"}
                          style={{maxWidth: 950}}>

                        {/*Search*/}
                        <TextField
                            className={"mr2 mr0-md w100-md mt2 formField"}
                            id="search_field_search"
                            name="term"
                            style={{width: 310, marginTop: '0.5rem', marginRight: '0.5rem'}}
                            label={process.env.LANG === "FR" ? "Recherche: Titre, Ville, Enseigne, etc.." : "Search: Job Title, Location, etc.."}
                            variant="outlined"/>

                        {/*<div className={"r afe"}>*/}
                        {/*Job Type*/}
                        <FormControl className={"mr2 mr0-md w100-md mt2 mt3-md formField"}
                                     style={{width: 200, marginTop: '0.5rem', marginRight: '0.5rem'}}>
                            <InputLabel>{process.env.LANG === "FR" ? "Type d'emploi" : "Job Type"}</InputLabel>
                            <Select
                                labelId="search-field-province-category"
                                id="search_field_category"
                                value={jobType}
                                label="Job Type"
                                name={"type"}
                                onChange={handleJobTypeChange}
                            >
                                <MenuItem value={"all"}>{process.env.LANG === "FR" ? "Toute" : "All Options"}</MenuItem>
                                <MenuItem value={"store"}>{process.env.LANG === "FR" ? "Magasins" : "Stores"}</MenuItem>
                                <MenuItem
                                    value={"dist"}>{process.env.LANG === "FR" ? "Centre de distribution" : "Distribution centres"}</MenuItem>
                                <MenuItem
                                    value={"head"}>{process.env.LANG === "FR" ? "Siège Social" : "Head office"}</MenuItem>
                            </Select>
                        </FormControl>

                        {/*Submit Button*/}
                        <div className={"c w100-md mt2-lg mt3-md formField"}>
                            <button className={"button-2 m0"} style={{height: 53}}
                                    type={"submit"}>{process.env.LANG === "FR" ? "Recherche" : "Search Jobs"}</button>
                        </div>
                        {/*</div>*/}
                    </form>
                </section>

                {/*MAP*/}
                <MapContainer maxBounds={[[60, -140], [40, -50]]} tap={false} style={{height: "700px"}}
                              center={[50, -101.94533896166213]}
                              zoom={4} scrollWheelZoom={true} attributionControl={false}>
                    <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                               minZoom={4} url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    <FeatureGroup ref={groupRef} name="Jobs">
                        <MarkerClusterGroup eventHandlers={{
                            click: (e) => {
                                if (map.getZoom() < 8) {
                                    map.setView([e.latlng.lat + 1, e.latlng.lng], 8);
                                }
                            }
                        }} ref={clusterRef} key={geoJsonKey} iconCreateFunction={createClusters}>
                            <MapConsumer>
                                {(m) => {
                                    map = m;
                                    return null;
                                }}
                            </MapConsumer>
                            <GeoJSON key={geoJsonKey} onEachFeature={createPopups} data={displayedMarkers}/>
                        </MarkerClusterGroup>
                    </FeatureGroup>
                    <HandleMapZoom/>
                    <UpdateMapPosition
                        geoJsonKey={geoJsonKey}
                        groupRef={groupRef}
                        displayedMarkers={displayedMarkers}
                        jobType={jobType}
                        coords={coords}
                    />
                    <AddLocate geoJsonKey={geoJsonKey} coords={coords} data={displayedMarkers}/>
                </MapContainer>

            </>)
    }
    return null;
}

export default JobMap







