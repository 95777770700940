import {useEffect} from "react"
import {useMap} from "react-leaflet"
import Locate from "leaflet.locatecontrol"
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css"
import distance from "./helper-functions"

const AddLocateLogic = ({coords, data, geoJsonKey}) => {

    // Access the map  context with the useMap hook
    const map = useMap()

    // Add locate control once the map loads
    useEffect(() => {
        const locateOptions = {
            position: "topleft",
            initialZoomLevel: 10,
            showPopup: false,
        }
        const locateControl = new Locate(locateOptions)
        locateControl.addTo(map)
    }, [map])

    useEffect(() => {

        if (data !== undefined) {
            // console.log("COORDS~!", coords, data)
            for (let job in data) {
                if (data.hasOwnProperty(job)) {
                    data[job]['distance'] = distance(parseFloat(data[job].geometry.coordinates[1]), parseFloat(data[job].geometry.coordinates[0]), coords[0], coords[1], 'K')
                }
            }
            data.sort(function (a, b) {
                let distA = a.distance
                let distB = b.distance
                if (distA < distB) {
                    return -1;
                } else if (distA > distB) {
                    return 1;
                }
                return 0;
            });
            if (data[0] !== undefined && coords[0] !== null) {
                if (data[0].geometry !== undefined) {
                    if (data[0].geometry.coordinates[0] !== null) {
                        // console.log("Setting Map View! ", coords, data)
                        map.setView([data[0].geometry.coordinates[1], data[0].geometry.coordinates[0]], 10, {})
                    }
                }

            }

        }
    }, [coords, data]);

    return null
}

export default AddLocateLogic