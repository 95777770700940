import * as React from "react";
import Header from "../components/Header/Header";
import "./../sass/main.scss";
import Footer from "../components/Footer/Footer";
import JobMap from "../components/JobMap";
import { CircularProgress } from "@mui/material";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

// Images
import lowesLady from "../images/lowes-lady.png";
import lowesGuy from "../images/lowes-guy.png";
import lowesEmployee from "../images/lowes-employee.png";
import bigMarker from "../images/jobs-near-you.svg";
import bigMarkerFR from "../images/jobs-near-you-fr.svg";
// import bigMarker from "../images/careers-heading.svg"
// import bigMarkerFR from "../images/careers-heading-fr-1.svg"
import banners from "../images/Rona-reno-depot-banner.svg";
import employee from "../images/employee.png";
import ccdiLogo from "../images/20151013 - CCDI - Logo - Employer Partner.png";
import ccdiLogoFr from "../images/20151013 - CCDI - Logo - Employer Partner-fr.png";
import call2Recycle from "../images/Call2Recycle.jpeg";
import call2RecycleFr from "../images/Call2Recycle-fr.jpg";
import energyStar from "../images/Energy Star_EN.jpeg";
import energyStarFr from "../images/Energy Star_fr.jpg";
import greenest2021 from "../images/greenest2021-english.png";
import greenest2021Fr from "../images/greenest2021-fr.png";
import mtlTopEmployer from "../images/Logo Mtl top employer FR.png";
import mtlTopEmployerFr from "../images/Logo Mtl top employer-fr.png";
import era2019 from "../images/ERA 2019 carr_EN.png";
import era2019Fr from "../images/ERA 2019 carr_fr.png";
import hiringEvent from "../images/hero-en-2-2024.jpg";
import hiringEventFr from "../images/hero-fr-2-2024.jpg";

SwiperCore.use([Autoplay, Pagination, Navigation]);
let EN_URL = process.env.EN_URL ? process.env.EN_URL : "/";
let FR_URL = process.env.FR_URL ? process.env.FR_URL : "/";
const IndexPage = () => {
  let isFrench = process.env.LANG === "FR";
  let isInternal = process.env.INT_EXT === "internal";
  return (
    <>
      <Header
        title={
          isFrench
            ? "Carrières chez RONA inc., Rona, et Réno Dépôt"
            : "Careers at RONA inc., Rona, and Reno Depot"
        }
        description={
          isFrench
            ? "Vous cherchez à entamer un nouveau chapitre avec RONA inc.? Consultez nos offres d'emploi ici et commencez votre carrière chez RONA inc., Rona, et Réno Dépôt."
            : "Looking to start a new chapter with RONA inc.? Search our openings here and start your career with RONA inc., Rona, and Reno Depot"
        }
        hidden={false}
        lang={process.env.LANG ? process.env.LANG : "en"}
        lang_link={isFrench ? `${EN_URL}/` : `${FR_URL}/`}
        bodyClasses={`page-index${isInternal ? " page-index-internal" : ""}`}
      />
      <main id={"main"}>
        <section>
          <div className={"maxWidth pt5 pt0-lg"}>
            {/*{isInternal ? <h1 class={'tac-lg'}*/}
            {/*                  style={{marginBottom: 20}}>{isFrench ? 'Site carrière interne' : 'Internal Career Site'}</h1> : ''}*/}
            <div
              className={
                "d-n-xxl bs panel-ctn panel-fullwidth r jc ac d-n-lg lowes-bg-img"
              }
            >
              <Swiper
                modules={[Navigation, Pagination, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                loop={!isInternal}
                autoplay={isInternal ? false : { delay: 5000 }}
                pagination={{ clickable: true }}
                style={{ height: "100%", width: "100%" }}
              >
                {isInternal ? (
                  <>
                    {/*<SwiperSlide>*/}
                    {/*    <div className={`${isFrench ? 'slider-image-1-fr' : 'slider-image-1'} r ac`}/>*/}
                    {/*    <a href={isFrench ? '/salon-carriere/' : '/career-fair/'} target={'blank'}*/}
                    {/*       className={'r ac jfs bottom-banner'}>*/}
                    {/*        <h3 style={isFrench ? {fontSize: 18} : {}}*/}
                    {/*            className={isFrench ? 'ml5 pl2' : 'ml6 pl4'}>{isFrench ? 'Cliquez ici pour en savoir plus sur notre évènement de l\'emploi interne du 13 juin' : 'Click here to learn about our Internal Career Fair on June 13th'}</h3>*/}
                    {/*        <a href={isFrench ? '/salon-carriere/' : '/career-fair/'} target={'blank'}*/}
                    {/*           className={'ml3'}>*/}
                    {/*            <button*/}
                    {/*                className={'button'}>{isFrench ? 'En savoir plus' : 'Learn More'}</button>*/}
                    {/*        </a>*/}
                    {/*    </a>*/}
                    {/*</SwiperSlide>*/}
                    <SwiperSlide>
                      <div
                        className={`${isFrench ? "slider-image-2-fr" : "slider-image-2"
                          } r ac`}
                      />
                      <a
                        href="https://lowesprod.service-now.com/myhr?id=esc_kb_article&sysparm_article=KB0021485"
                        target={"blank"}
                        className={"r ac jfs bottom-banner"}
                      >
                        <h3
                          style={isFrench ? { fontSize: 16 } : {}}
                          className={isFrench ? "ml5 pl2" : "ml6 pl4"}
                        >
                          {isFrench
                            ? "Cliquez ici pour référer une connaissance sur n’importe quel poste disponible chez RONA inc.!"
                            : "Click here to refer a candidate to any available positions at RONA inc.!"}
                        </h3>
                        <a
                          href={
                            isFrench
                              ? "https://lowesprod.service-now.com/myhr?id=esc_kb_article&sysparm_article=KB0021486"
                              : "https://lowesprod.service-now.com/myhr?id=esc_kb_article&sysparm_article=KB0021485"
                          }
                          target={"blank"}
                          className={"ml3"}
                        >
                          <button className={"button"}>
                            {isFrench ? "Référer" : "Refer"}
                          </button>
                        </a>
                      </a>
                    </SwiperSlide>
                  </>
                ) : (
                  <>
                    <SwiperSlide className="r">
                      <a
                        href={
                          isFrench
                            ? "https://ronaincembauche.ca/"
                            : "https://ronainchiring.ca/"
                        }
                        target="_blank"
                        className={`h100 w100 ${isFrench ? 'event-pic-fr' : 'event-pic-en'}`}
                      >
                      </a>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="r ac w100 h100">
                        <div
                          className={"c jc p3 pl4 ml6"}
                          style={{ maxWidth: 415 }}
                        >
                          <a
                            target="_blank"
                            style={{ textDecoration: "underline !important" }}
                            href={
                              isFrench
                                ? "https://www.ronainc.ca/fr/a-propos"
                                : "https://www.ronainc.ca/en/about"
                            }
                          >
                            <h2
                              style={{
                                fontSize: "2.5rem",
                                textAlign: "center",
                                color: "#ffffff",
                                textDecoration: "underline !important",
                              }}
                            >
                              {isFrench
                                ? "Découvrez RONA inc."
                                : "Discover RONA inc."}
                            </h2>
                          </a>
                        </div>
                        <div className={"c pl5 ac jc h100"}>
                          <img
                            src={banners}
                            style={{ maxHeight: 120 }}
                            height={346}
                            width={382}
                            alt={"RONA inc. Employees"}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="r ac w100 h100">
                        <div
                          className={"c jc p3 pl4 ml6"}
                          style={{ maxWidth: 415 }}
                        >
                          <a
                            target="_blank"
                            style={{ textDecoration: "underline !important" }}
                            href={
                              isFrench
                                ? "https://www.ronainc.ca/fr/responsabilite-corporative/responsabilite-d-entreprise-notre-bilan"
                                : "https://www.ronainc.ca/en/corporate-responsibility/csr-highlights"
                            }
                          >
                            <h2
                              style={{
                                fontSize: "2.5rem",
                                textAlign: "center",
                                color: "#ffffff",
                                textDecoration: "underline !important",
                              }}
                            >
                              {isFrench
                                ? "Aidez-nous à bâtir un avenir durable, aujourd’hui"
                                : "Help us build a better tomorrow, today"}
                            </h2>
                          </a>
                        </div>
                        <div className={"c pl5 ac jc h100"}>
                          <img
                            src={banners}
                            style={{ maxHeight: 120 }}
                            height={346}
                            width={382}
                            alt={"RONA inc. Employees"}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="r ac w100 h100">
                        <div
                          className={"c jc p3 pl4 ml6"}
                          style={{ maxWidth: 415 }}
                        >
                          <a
                            target="_blank"
                            style={{ textDecoration: "underline !important" }}
                            href={
                              isFrench
                                ? "https://www.ronainc.ca/fr/responsabilite-corporative/responsabilite-d-entreprise-notre-bilan"
                                : "https://www.ronainc.ca/en/corporate-responsibility/communities"
                            }
                          >
                            <h2
                              style={{
                                fontSize: "2.5rem",
                                textAlign: "center",
                                color: "#ffffff",
                                textDecoration: "underline !important",
                              }}
                            >
                              {isFrench
                                ? "Nous faisons la différence"
                                : "How we make a difference"}
                            </h2>
                          </a>
                        </div>
                        <div className={"c pl5 ac jc h100"}>
                          <img
                            src={banners}
                            style={{ maxHeight: 120 }}
                            height={346}
                            width={382}
                            alt={"RONA inc. Employees"}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                )}
              </Swiper>
              <div
                className={"nearest-container bs pos-abs d-n-xxl p4"}
                style={{ zIndex: 300 }}
              >
                <img
                  className={"mb1 mt6"}
                  src={isFrench ? bigMarkerFR : bigMarker}
                  alt={"Marker"}
                />

                <a
                  href="/"
                  id="location_1_href"
                  class={"location-card mt4 c tac jc ac p3"}
                >
                  <p id="location_1_city" className={"m0"}>
                    ...
                  </p>
                  <h4 id="location_1_jobs" className={"mt2 mb0"}>
                    -
                  </h4>
                </a>
                <a
                  href="/"
                  id="location_2_href"
                  style={{ display: "none" }}
                  class={"location-card mt3 c tac jc ac p3"}
                >
                  <p id="location_2_city" className={"m0"}>
                    ...
                  </p>
                  <h4 id="location_2_jobs" className={"mt2 mb0"}>
                    -
                  </h4>
                </a>
                <div className={"r jc ac tac mt3"}>
                  <p>
                    <a
                      id={"location_all_jobs"}
                      href={isFrench ? "/recherche/" : "/search"}
                    >
                      <u>{isFrench ? "Afficher d'autres " : "View more "}</u>
                    </a>
                    {isFrench
                      ? "rôles dans votre région"
                      : "job openings in your area"}
                  </p>
                </div>
              </div>
            </div>
            <div
              className={
                "nearest-container nearest-container2 dn d-f-xxl c pb4"
              }
            >
              <div
                className={"r-c-lg jc ac fww p4 pb0 nearest-container-content"}
              >
                <img
                  className={"mb3 mt1 mr4 marker"}
                  src={isFrench ? bigMarkerFR : bigMarker}
                  alt={"Marker"}
                />

                <a
                  href="/"
                  id="location_1_href2"
                  class={"location-card mx3 mx0-md my2-md my3 c tac jc ac p3"}
                >
                  <p id="location_1_city2" className={"m0"}>
                    ...
                  </p>
                  <h4 id="location_1_jobs2" className={"mt2 mb0"}>
                    -
                  </h4>
                </a>
                <a
                  style={{ display: "none" }}
                  href="/"
                  id="location_2_href2"
                  class={"location-card mx3 mx0-md my2-md my3 c tac jc ac p3"}
                >
                  <p id="location_2_city2" className={"m0"}>
                    ...
                  </p>
                  <h4 id="location_2_jobs2" className={"mt2 mb0"}>
                    -
                  </h4>
                </a>
              </div>
              <div className={"r jc ac tac mt3 mb2"}>
                <p>
                  <u>
                    <a
                      id={"location_all_jobs2"}
                      href={isFrench ? "/recherche/" : "/search/"}
                    >
                      {isFrench ? "Afficher d'autres " : "View more "}
                    </a>
                  </u>
                  {isFrench ? "rôles dans votre région" : "roles in your area"}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className={"maxWidth"}>
            <div id={"job_progress_circle"} className={"r w100 jc ac my6 "}>
              <CircularProgress style={{ width: 60, height: 60 }} />
            </div>
            <JobMap />
          </div>
        </section>
        <section>
          <div className={"maxWidth pt0"}>
            <div className="intro col-12 mt0">
              <div className="intro-content col-12 col-md-10 px-15">
                <p className={"mt0"}>
                  {isFrench
                    ? "Chez RONA inc., nos employés cultivent leur passion chaque jour. Nos équipes sont animées par la volonté d’aider les gens à faire de leurs projets une réalité, qu’il s’agisse de trouver la couleur idéale pour peindre la chambre d’un nouveau-né, d’offrir des idées de jardinage uniques pour créer un havre de paix extérieur parfait pour la retraite, ou encore de développer des solutions novatrices pour améliorer nos façons de faire."
                    : "At RONA inc., our employees let their passion blossom every day. Our teams are driven by the desire to help people bring their projects to life, whether that means finding the perfect paint colour for a new baby’s room, offering unique gardening ideas to create the ideal retirement haven, or developing innovative solutions to improve the way we do business."}
                </p>
                <p>
                  {isFrench
                    ? "Chez nous, votre travail et vos idées contribuent à bâtir une organisation florissante et diversifiée. Votre voix sera toujours entendue et valorisée. Vous pourrez mettre en lumière et développer encore davantage l’ensemble des compétences qui vous rend unique et découvrirez des possibilités de carrière à la hauteur de vos ambitions."
                    : "Here, your work and ideas will contribute to building a flourishing organization. Your voice will always be heard and valued. You will be able to showcase and further develop your unique set of skills and discover career opportunities that live up to your ambitions."}
                </p>
                <p className={"mb0"}>
                  {isFrench
                    ? "Si vous cherchez à faire ce que vous aimez, à grandir au sein d’une grande famille et à vous perfectionner, nous pourrions être parfaits l’un pour l’autre."
                    : "So if you are looking to do what you love, and to grow and evolve within our family, we could be perfect for each other."}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className={"maxWidth"}>
            <div className="mt6 mid-page-ctn">
              <div className="panel-ctn panel-horizontal col-12 col-lg-10">
                <a name="diversity-inclusion" className="page-anchor" />
                <div className="row">
                  <div className="panel-pic col-12 col-md-6 diversity-inclusion" />
                  <div className="panel-text col-12 col-md-6 ">
                    <h2>
                      {isFrench
                        ? "Diversité et inclusion"
                        : "Diversity and inclusion"}
                    </h2>
                    <div>
                      <span>
                        {isFrench
                          ? "Parce que nous croyons que nos différences représentent une force inestimable, nous accordons une grande importance aux valeurs de diversité et d’inclusion. Nous sommes convaincus que c’est en rassemblant des individus uniques autour d’une même mission, celle de toujours mieux servir nos clients et nos communautés, et en leur permettant d’évoluer dans un environnement où leurs talents peuvent briller sans retenue, que nous parviendrons à atteindre les plus hauts sommets."
                          : "Because we believe that our differences constitute an invaluable strength, we attach a great importance to the values of diversity and inclusion. We are convinced that bringing together unique individuals around a single mission, that of always providing better service to our customers and communities, and creating an environment where their talents can truly shine, will allow us to achieve great success."}
                      </span>
                    </div>
                    <div className="panel-btn-ctn">
                      <a
                        className="btn btn-darkblue"
                        target={"_blank"}
                        rel={"nofollow"}
                        href={
                          isFrench
                            ? "https://www.ronainc.ca/fr/carrieres/diversite-et-inclusion"
                            : "https://www.ronainc.ca/en/careers/diversity-and-inclusion"
                        }
                      >
                        <span>
                          {isFrench ? "EN SAVOIR PLUS" : "Learn more"}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="text-center join-team-title">
                {isFrench ? "" : "Prizes and awards"}
              </h2>
              <div className="joinOurTeam">
                <ul className="mw100 row icon-list justify-content-center">
                  <li
                    style={{ listStyle: "none" }}
                    className="col-12 col-md-2 icon-item"
                  >
                    <img
                      src={isFrench ? ccdiLogoFr : ccdiLogo}
                      alt="CCDI Logo"
                    />
                  </li>
                  <li
                    style={{ listStyle: "none" }}
                    className="col-12 col-md-2 icon-item"
                  >
                    <a
                      target="_blank"
                      rel="nofollow noreferrer"
                      href={
                        isFrench
                          ? "https://www.ronainc.ca/fr/nouvelles/un-neuvieme-titre-de-chef-de-file-en-durabilite-pour-lowes-canada"
                          : "https://ronainc.ca/en/news/lowes-canada-receives-a-ninth-leader-in-sustainability-award"
                      }
                    >
                      <img
                        src={isFrench ? call2RecycleFr : call2Recycle}
                        alt="Leader in Sustainability 2020 - Call2Recycle Canada"
                      />
                    </a>
                  </li>
                  <li
                    style={{ listStyle: "none" }}
                    className="col-12 col-md-2 icon-item"
                  >
                    <a
                      target="_blank"
                      rel="nofollow noreferrer"
                      href={
                        isFrench
                          ? "https://www.ronainc.ca/fr/nouvelles/lowes-canada-remporte-un-premier-prix-energy-star-canada-pour-son-excellence-en-efficacite-energetique"
                          : "https://ronainc.ca/en/news/lowes-canada-wins-its-first-energy-star-canada-award-in-recognition-of-its-excellence-in-energy-efficiency"
                      }
                    >
                      <img
                        src={isFrench ? energyStarFr : energyStar}
                        alt="Energy Start Reward"
                      />
                    </a>
                  </li>
                  <li
                    style={{ listStyle: "none" }}
                    className="col-12 col-md-2 icon-item"
                  >
                    <a
                      target="_blank"
                      rel="nofollow noreferrer"
                      href={
                        isFrench
                          ? "https://www.ronainc.ca/fr/nouvelles/lowes-canada-se-classe-parmi-les-employeurs-les-plus-ecolos-au-pays"
                          : "https://ronainc.ca/en/news/lowes-canada-recognized-as-one-of-the-country-s-greenest-employers"
                      }
                    >
                      <img
                        src={isFrench ? greenest2021Fr : greenest2021}
                        alt="Greenest Employer"
                      />
                    </a>
                  </li>
                  <li
                    style={{ listStyle: "none" }}
                    className="col-12 col-md-2 icon-item"
                  >
                    <a
                      target="_blank"
                      rel="nofollow noreferrer"
                      href={
                        isFrench
                          ? "https://www.ronainc.ca/fr/nouvelles/lowes-canada-nommee-au-palmares-des-meilleurs-employeurs-de-montreal--2021"
                          : "https://ronainc.ca/en/news/lowes-canada-named-one-of-montreal-s-top-employers"
                      }
                    >
                      <img
                        src={isFrench ? mtlTopEmployerFr : mtlTopEmployer}
                        alt="Montreal Top Employers"
                      />
                    </a>
                  </li>
                  <li
                    style={{ listStyle: "none" }}
                    className="col-12 col-md-2 icon-item"
                  >
                    <a
                      target="_blank"
                      rel="nofollow noreferrer"
                      href={
                        isFrench
                          ? "https://www.ronainc.ca/fr/nouvelles/lowes-canada-remporte-un-prix-leadership-philanthropique-lors-du-gala-2019-des-prix-d'excellence-dans-le-commerce-de-detail-du-cccd"
                          : "https://ronainc.ca/en/news/lowe's-canada-wins-a-philanthropic-leadership-award-at-the-2019-rcc-excellence-in-retailing-awards-gala"
                      }
                    >
                      <img
                        src={isFrench ? era2019Fr : era2019}
                        alt="ERA 2019"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer lang={process.env.LANG ? process.env.LANG : "en"} />
    </>
  );
};

export default IndexPage;
